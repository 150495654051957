import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "/app/frontend/src/app/providers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/frontend/src/components/AccessDeniedModal.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/frontend/src/components/Toast.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/frontend/src/components/TopBar.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/@rainbow-me/rainbowkit/dist/index.css");
;
import(/* webpackMode: "eager" */ "/app/frontend/src/styles/globals.css");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/utils/fonts.ts\",\"import\":\"IBM_Plex_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"500\",\"600\",\"700\"],\"display\":\"swap\",\"variable\":\"--font-ibm-sans\"}],\"variableName\":\"sans\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/utils/fonts.ts\",\"import\":\"IBM_Plex_Serif\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"600\",\"700\"],\"display\":\"swap\",\"variable\":\"--font-ibm-serif\"}],\"variableName\":\"serif\"}");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/utils/fonts.ts\",\"import\":\"IBM_Plex_Mono\",\"arguments\":[{\"subsets\":[\"latin\"],\"weight\":[\"400\",\"600\",\"700\"],\"display\":\"swap\",\"variable\":\"--font-ibm-mono\"}],\"variableName\":\"mono\"}");
