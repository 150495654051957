"use client";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { ConfigProvider } from "@/context/configContext";

import { WagmiProvider } from "wagmi";

import { AuthorizationProvider } from "@/context/authorizationContext";
import { RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { useConfigContext } from "@/context/configContext";
const queryClient = new QueryClient();

export function Providers({ children }: { children: React.ReactNode }) {
  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider>
        <WagmiConfig>
          {children}
        </WagmiConfig>
      </ConfigProvider>
    </QueryClientProvider>
  );
}

function WagmiConfig({ children }: { children: React.ReactNode }) {
  const { chainConfig } = useConfigContext();
  return (
    <WagmiProvider config={chainConfig}>
      <RainbowKitProvider>
        <AuthorizationProvider>{children}</AuthorizationProvider>
      </RainbowKitProvider>
    </WagmiProvider>
  );
}
